<template>
  <v-container>
    <v-card elevation="0" width="100%" class="transparent mb-5">
      <v-card-title flat class="transparent">
        <div class="d-flex">
          <h1 class="headline">
            {{ isEdit ? $t('medicalTests.edit_nomenclator') : $t('medicalTests.new_nomenclator') }}
          </h1>
        </div>
      </v-card-title>
      <v-card>
        <v-card-text>
          <form>
            <v-layout wrap>
              <v-flex xs12 sm6 lg6>
                <v-select
                  v-model="filter.specialtyId"
                  :label="`${$t('medicalTests.specialty')} *`"
                  outlined
                  dense
                  class="mr-sm-1"
                  :items="activeSpecialities"
                  item-text="description"
                  item-value="id"
                  :loading="isLoadingSpecialties"
                  :disabled="!activeSpecialities.length || isDisabled"
                  :error-messages="specialtyValidationError"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-select
                  v-model="filter.groupId"
                  :label="`${$t('medicalTests.group')} *`"
                  outlined
                  dense
                  class="mr-sm-1"
                  :items="activeGroups"
                  item-text="description"
                  item-value="id"
                  :loading="isLoadingGroups"
                  :disabled="!activeGroups.length || isDisabled"
                  :error-messages="groupValidationError"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-select
                  v-model="filter.collectionId"
                  :label="`${$t('medicalTests.collection')} *`"
                  outlined
                  dense
                  :items="activeCollections"
                  item-text="description"
                  item-value="id"
                  :loading="isLoadingCollections"
                  :disabled="!activeCollections.length || isDisabled"
                  :error-messages="collectionValidationError"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-select
                  v-model="filter.subgroupId"
                  :label="`${$t('medicalTests.subgroup')} *`"
                  outlined
                  dense
                  class="mr-sm-1"
                  :items="activeSubGroups"
                  item-text="description"
                  item-value="id"
                  :loading="isLoadingSubGroups"
                  :disabled="!activeSubGroups.length || isDisabled"
                  :error-messages="subGroupValidationError"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="act.code"
                  :label="$t('medicalTests.codeCiepq')"
                  outlined
                  dense
                  class="mr-1"
                  :disabled="isDisabled"
                  :error-messages="codeValidationError"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="act.externalId"
                  :label="$t('medicalTests.externalId')"
                  outlined
                  dense
                  class="ml-1"
                  :disabled="isDisabled"
                />
              </v-flex>
              <v-flex xs12 sm12 lg12>
                <v-textarea
                  v-model="act.description"
                  dense
                  outlined
                  :label="`${$t('medicalTests.actDescription')} *`"
                  :disabled="isDisabled"
                  :error-messages="descriptionValidationError"
                />
              </v-flex>

              <div class="mb-2">
                <h1 class="title">
                  {{ $t('medicalTests.codesCie9') }}
                </h1>
              </div>

              <v-flex xs12 sm12 lg12>
                <template>
                  <v-layout v-for="(item, index) in act.cieps" :key="index">
                    <v-flex lg2>
                      <v-text-field
                        v-model="item.code"
                        dense
                        outlined
                        type="text"
                        class="mr-1"
                        :label="$t('medicalTests.codeCie9')"
                        :error-messages="cieCodeValidationError(index)"
                        :disabled="isDisabled"
                      />
                    </v-flex>
                    <div v-if="isDelete(index)" class="ml-3 text-center">
                      <v-btn icon color="error" :disabled="isDisabled" @click="deleteCie(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-layout>
                  <v-btn color="primary" text :disabled="isDisabled" @click="addCie">
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    {{ $t('medicalTests.addAnotherCode') }}
                  </v-btn>
                </template>
              </v-flex>
            </v-layout>
          </form>
        </v-card-text>
      </v-card>
      <div class="text-right mt-3">
        <v-btn class="ma-2" color="primary" outlined @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn :disabled="isDisabled" :loading="submitting" color="primary" @click="save">
          {{ $t('common.save') }}
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { baseNameRoutes } from '@/router/paths';

import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { getHeader, medicalTestsUrl } from '@/config/config';

const cie = { code: '' };
const ACTS_ENDPOINT = medicalTestsUrl + 'acts';
const CODE_REGEX = /^[0-9]{4}$/;

export default {
  setup() {
    const {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      loadSpecialties,
      loadGroups,
      loadCollections,
      loadSubGroups,
    } = useNomenclator();

    loadSpecialties();

    return {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      loadGroups,
      loadCollections,
      loadSubGroups,
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      submitting: false,
      submittedWithErrors: false,
      isEdit: false,
      act: {
        specialtyId: '',
        groupId: '',
        collectionId: '',
        subgroupId: '',
        code: '',
        externalId: '',
        description: '',
        cieps: [],
      },
      retrievingData: false,
    };
  },

  validations() {
    return {
      act: {
        specialtyId: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          $autoDirty: true,
        },
        groupId: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          $autoDirty: true,
        },
        collectionId: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          $autoDirty: true,
        },
        subgroupId: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          $autoDirty: true,
        },
        description: {
          required: helpers.withMessage(this.$t('common.obligatorio'), required),
          $autoDirty: true,
        },
        code: {
          regexValidation: helpers.withMessage(this.$t('medicalTests.invalidCiep9'), helpers.regex(CODE_REGEX)),
          $autoDirty: true,
        },
        cieps:
          this.act.cieps.length > 1
            ? {
                $each: helpers.forEach({
                  code: {
                    required: helpers.withMessage(this.$t('common.obligatorio'), required),
                  },
                }),
              }
            : {},
      },
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),

    isDisabled() {
      return this.submitting || this.retrievingData;
    },

    specialtyValidationError() {
      return this.submittedWithErrors ? this.v$.act.specialtyId.$errors.map(error => error.$message) : null;
    },

    groupValidationError() {
      return this.submittedWithErrors ? this.v$.act.groupId.$errors.map(error => error.$message) : null;
    },

    collectionValidationError() {
      return this.submittedWithErrors ? this.v$.act.collectionId.$errors.map(error => error.$message) : null;
    },

    subGroupValidationError() {
      return this.submittedWithErrors ? this.v$.act.subgroupId.$errors.map(error => error.$message) : null;
    },

    descriptionValidationError() {
      return this.submittedWithErrors ? this.v$.act.description.$errors.map(error => error.$message) : null;
    },

    codeValidationError() {
      return this.submittedWithErrors ? this.v$.act.code.$errors.map(error => error.$message) : null;
    },
  },

  watch: {
    'filter.specialtyId'(val) {
      if (!this.retrievingData) {
        this.act.specialtyId = val;
      }
    },
    'filter.groupId'(val) {
      if (!this.retrievingData) {
        this.act.groupId = val;
      }
    },
    'filter.collectionId'(val) {
      if (!this.retrievingData) {
        this.act.collectionId = val;
      }
    },
    'filter.subgroupId'(val) {
      if (!this.retrievingData) {
        this.act.subgroupId = val;
      }
    },
  },

  mounted() {
    if (!this.permissions.moduleMedicalTests) {
      this.redirectToDashboard();
    }
  },

  created() {
    this.isEditPage();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    hasModuleMedicalTests() {
      return this.permissions.moduleMedicalTests ?? false;
    },

    redirectToDashboard() {
      this.$router.push({ name: baseNameRoutes.dashboard });
    },

    redirectToMain() {
      this.$router.push({ name: baseNameRoutes.nomenclator });
    },

    cancel() {
      this.redirectToMain();
    },

    save() {
      this.v$.$touch();
      this.submitting = true;

      if (this.v$.$invalid) {
        this.submittedWithErrors = true;
        this.submitting = false;
        return;
      }

      if (this.isEdit) {
        this.update();
      } else {
        this.create();
      }
    },

    async create() {
      this.submitting = true;

      try {
        const response = await this.$http.post(
          ACTS_ENDPOINT,
          {
            ...this.act,
            cieps: this.act.cieps.filter(ciep => ciep.code),
          },
          {
            headers: getHeader(),
          }
        );

        if (response.status === 200) {
          this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.create_test_success'));
          this.delay(2000).then(() => this.redirectToMain());
        } else {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
        }
        this.submitting = false;
      } catch (e) {
        this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
      } finally {
        this.submitting = false;
      }
    },

    async update() {
      this.submitting = true;

      try {
        const response = await this.$http.put(`${ACTS_ENDPOINT}/${this.act.id}`, this.act, {
          headers: getHeader(),
        });

        if (response.status === 200) {
          this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.create_test_success'));
          this.delay(2000).then(() => this.redirectToMain());
        } else {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
        }
        this.submitting = false;
      } catch (e) {
        this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
      } finally {
        this.submitting = false;
      }
    },

    async isEditPage() {
      if (this.$route.params.id) {
        this.isEdit = true;
        await this.getEditData(this.$route.params.id);
      } else {
        this.act.cieps.push(Object.assign({}, cie));
      }
    },

    async getEditData(id) {
      this.retrievingData = true;
      const rawAct = await this.fetchAct(id);
      this.act = {
        id: id,
        specialtyId: rawAct.specialty.id,
        groupId: rawAct.group.id,
        collectionId: rawAct.collection.id,
        subgroupId: rawAct.subgroup.id,
        code: rawAct.code,
        description: rawAct.description,
        cieps: rawAct.cieps,
      };

      this.filter.specialtyId = this.act.specialtyId;
      await this.loadGroups(this.act.specialtyId);
      this.filter.groupId = this.act.groupId;
      await this.loadCollections(this.act.groupId);
      this.filter.collectionId = this.act.collectionId;
      await this.loadSubGroups(this.act.collectionId);
      this.filter.subgroupId = this.act.subgroupId;

      this.retrievingData = false;
    },

    async fetchAct(id) {
      const raw = await fetch(`${ACTS_ENDPOINT}/${id}`, {
        headers: getHeader(),
      });
      return await raw.json();
    },

    addCie() {
      this.act.cieps.push(Object.assign({}, cie));
    },

    deleteCie(index) {
      this.act.cieps.splice(index, 1);
    },

    cieCodeValidationError(index) {
      return this.submittedWithErrors && this.v$.act.cieps.$each
        ? this.v$.act.cieps.$each.$response.$errors[index].code.map(error => error.$message)
        : null;
    },

    isDelete(index) {
      return index > 0 || this.act.cieps.length > 1;
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
  },
};
</script>
